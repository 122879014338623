import { ISelectFilterOption } from 'components/molecules/Select/Select';
import {
  ALT_GRAPH_KEY,
  ALT_KEY,
  APP_ELEMENT_ID,
  CONTROL_KEY,
  META_KEY,
  SHIFT_KEY,
} from 'constants/General';
import {
  BOOLEAN_FALSE_LABEL,
  BOOLEAN_TRUE_LABEL,
  SUCCESS_STATUS_CODES,
} from 'constants/misc';
import { ESeverity } from 'enums/General';
import {
  IContactInfo,
  IContract,
  IEnergyProductInfo,
  IIndexable,
  IMiscInfo,
} from 'interfaces/General';
import React, { ChangeEvent } from 'react';
import { TToEntityId } from 'types/ToEntity';

const SPECIAL_NUMBER_CHARACTERS = ['e', 'E', '+', '-', '.'];
const TO_ENTITY_ID_SEPARATOR = ';';
const ID_SEPARATOR = ':';

export const getIsProduction = (): boolean =>
  window.ETAG_DEPLOYMENT.reactConfig.bannerLabel === 'PROD';

/**
 * When an unexpected error happens, should we show toast notifications?
 * Generally we want these enabled in lower environments and disabled in prod,
 * but we have a flag that can force it to be enabled or disabled independent
 * of environment. If this flag is set to true or false, we take the value of the flag.
 * Otherwise, we return false for prod and true for all other environments.
 */
export const showCapturedErrorToastNotifications = (): boolean => {
  if (
    window.ETAG_DEPLOYMENT.reactConfig.showCapturedErrorToastNotifications !==
    undefined
  ) {
    return window.ETAG_DEPLOYMENT.reactConfig
      .showCapturedErrorToastNotifications;
  }
  return !getIsProduction();
};

export const isNonEmptyValue = <T>(
  value: T | '' | null | undefined,
): value is T => value !== undefined && value !== null && value !== '';

export const isEmptyValue = (value: any): boolean =>
  value === undefined || value === null || value === '';

export const filterSpecialCharacters = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (SPECIAL_NUMBER_CHARACTERS.includes(event.key)) {
    event.preventDefault();
  }
};

export const eventToStringOrNull = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): string | null =>
  isEmptyValue(event.target.value) ? null : event.target.value;

export const isSuccessStatus = (status: number): boolean =>
  SUCCESS_STATUS_CODES.includes(status);

export const decodeId = (id: string): string[] => {
  const ids: string[] = id.split(ID_SEPARATOR);

  const extractedToEntityId: TToEntityId | undefined = ids
    .map((id: string): string | undefined => {
      const matches: RegExpMatchArray | null = id.match(
        new RegExp(`(.+)${TO_ENTITY_ID_SEPARATOR}`),
      );
      return matches === null ? undefined : matches[1];
    })
    .find((value: string | undefined): boolean => !isEmptyValue(value));

  return [extractedToEntityId === undefined ? '' : extractedToEntityId].concat(
    id.replace(
      new RegExp(`${extractedToEntityId}${TO_ENTITY_ID_SEPARATOR}`, 'g'),
      '',
    ),
  );
};

export const encodeTenantIds = (ids: string[], tenant: string): string => {
  return `${tenant}${TO_ENTITY_ID_SEPARATOR}${ids
    .join(ID_SEPARATOR)
    .replace(new RegExp(`${tenant}${TO_ENTITY_ID_SEPARATOR}`, 'g'), '')}`;
};

export const encodeIds = (ids: string[], toEntityId?: TToEntityId): string => {
  const extractedToEntityId: TToEntityId | undefined =
    toEntityId === undefined
      ? ids
          .map((id: string): string | undefined => {
            const matches: RegExpMatchArray | null = id.match(
              new RegExp(`(.+)${TO_ENTITY_ID_SEPARATOR}`),
            );
            return matches === null ? undefined : matches[1];
          })
          .find((value: string | undefined): boolean => !isEmptyValue(value))
      : toEntityId;

  if (extractedToEntityId === undefined) {
    throw new Error('Encoding ids failed due to missing toEntityId.');
  }

  return `${extractedToEntityId}${TO_ENTITY_ID_SEPARATOR}${ids
    .join(ID_SEPARATOR)
    .replace(
      new RegExp(`${extractedToEntityId}${TO_ENTITY_ID_SEPARATOR}`, 'g'),
      '',
    )}`;
};

export const shallowObjectCompare = <T>(
  obj1: T & IIndexable,
  obj2: T & IIndexable,
  excludeKeys?: string[],
): boolean =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1)
    .filter((key: string): boolean =>
      excludeKeys === undefined ? true : !excludeKeys.includes(key),
    )
    // eslint-disable-next-line no-prototype-builtins
    .every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);

export const shallowListCompare = <T>(
  list1: T[] | null | undefined,
  list2: T[] | null | undefined,
  checkEqualityUpToReordering?: boolean,
): boolean => {
  if (list1 === null || list1 === undefined) {
    return list2 === null || list2 === undefined;
  }
  if (list2 === null || list2 === undefined) {
    return false;
  }
  if (list1.length !== list2?.length) {
    return false;
  }
  let listToCompare1: T[];
  let listToCompare2: T[];
  if (checkEqualityUpToReordering) {
    listToCompare1 = [...list1].sort();
    listToCompare2 = [...list2].sort();
  } else {
    listToCompare1 = list1;
    listToCompare2 = list2;
  }
  for (let index: number = 0; index < listToCompare1.length; index += 1) {
    if (listToCompare1[index] !== listToCompare2[index]) {
      return false;
    }
  }
  return true;
};

export const selectOptionLabelFilter = (
  input: string,
  option?: ISelectFilterOption,
) =>
  option === undefined || option.label === undefined || option.label === null
    ? false
    : option.label
        .toLocaleString()
        .toLocaleLowerCase()
        .includes(input.toLocaleLowerCase());

export const energyProductInfoEqual = (
  a: IEnergyProductInfo | null,
  b: IEnergyProductInfo | null,
): boolean => {
  if (a === null) {
    return b === null;
  }

  return (
    b !== null &&
    a.product_name === b.product_name &&
    a.product_ref === b.product_ref
  );
};

export const listEqual =
  <T>(equalityChecker: (a: T, b: T) => boolean) =>
  (a: T[] | null, b: T[] | null): boolean => {
    if (a === null) {
      return b === null;
    }

    return (
      b !== null &&
      a.length === b.length &&
      a.every(
        (a: T): boolean =>
          b.find((b: T): boolean => equalityChecker(a, b)) !== undefined,
      )
    );
  };

export const contactInfoEqual = (
  a: IContactInfo | null,
  b: IContactInfo | null,
): boolean => {
  if (a === null) {
    return b === null;
  }

  return (
    b !== null &&
    a.key === b.key &&
    a.contact === b.contact &&
    a.fax === b.fax &&
    a.phone === b.phone
  );
};

export const contactInfosEqual = listEqual(contactInfoEqual);

export const contractEqual = (
  a: IContract | null,
  b: IContract | null,
): boolean => {
  if (a === null) {
    return b === null;
  }

  return b !== null && a.key === b.key && a.contract === b.contract;
};

export const contractsEqual = listEqual(contractEqual);

export const miscInfoEqual = (
  a: IMiscInfo | null,
  b: IMiscInfo | null,
): boolean => {
  if (a === null) {
    return b === null;
  }

  return (
    b !== null && a.key === b.key && a.token === b.token && a.value === b.value
  );
};

export const miscInfosEqual = listEqual(miscInfoEqual);

export const energyProductInfoToUid = (
  energyProductInfo: IEnergyProductInfo | null | undefined,
): string =>
  isEmptyValue(energyProductInfo)
    ? ''
    : energyProductInfo!.product_ref.toString();

export const clickOnAppAfterDelay = (delayInFrames: number) => {
  const appElement: HTMLElement | null =
    document.getElementById(APP_ELEMENT_ID);

  if (appElement !== null) {
    const requestAnimationFrame = (count: number) => {
      if (count === 0) {
        appElement.dispatchEvent(
          new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
          }),
        );
      } else {
        window.requestAnimationFrame(() => {
          requestAnimationFrame(count - 1);
        });
      }
    };

    requestAnimationFrame(delayInFrames);
  }
};

export const isOnlyShiftKey = (keyboardEvent: KeyboardEvent): boolean =>
  !keyboardEvent.altKey &&
  !keyboardEvent.ctrlKey &&
  !keyboardEvent.metaKey &&
  (keyboardEvent.key === SHIFT_KEY || keyboardEvent.keyCode === 16);

export const isShiftShortcut = (
  keyboardEvent: KeyboardEvent,
  key: string,
): boolean =>
  !keyboardEvent.altKey &&
  !keyboardEvent.ctrlKey &&
  !keyboardEvent.metaKey &&
  keyboardEvent.shiftKey &&
  keyboardEvent.key === key;

export const isControlShortcut = (
  keyboardEvent: KeyboardEvent,
  key: string,
): boolean =>
  !keyboardEvent.altKey &&
  (keyboardEvent.ctrlKey || keyboardEvent.metaKey) &&
  !keyboardEvent.shiftKey &&
  keyboardEvent.key.toLocaleLowerCase() === key.toLocaleLowerCase();

export const isModifierKey = (keyboardEvent: KeyboardEvent): boolean =>
  keyboardEvent.key === ALT_GRAPH_KEY ||
  keyboardEvent.key === ALT_KEY ||
  keyboardEvent.key === CONTROL_KEY ||
  keyboardEvent.key === META_KEY;

export const hasModifierKey = (
  keyboardEvent: KeyboardEvent,
  includeShift: boolean,
): boolean =>
  keyboardEvent.altKey ||
  keyboardEvent.ctrlKey ||
  keyboardEvent.metaKey ||
  (includeShift && keyboardEvent.shiftKey);

const isShiftDirection = (keyboardEvent: KeyboardEvent): boolean =>
  isShiftShortcut(keyboardEvent, 'Up') ||
  isShiftShortcut(keyboardEvent, 'ArrowUp') ||
  isShiftShortcut(keyboardEvent, 'Down') ||
  isShiftShortcut(keyboardEvent, 'ArrowDown') ||
  isShiftShortcut(keyboardEvent, 'Left') ||
  isShiftShortcut(keyboardEvent, 'ArrowLeft') ||
  isShiftShortcut(keyboardEvent, 'Right') ||
  isShiftShortcut(keyboardEvent, 'ArrowRight');

export const hasShiftKeyDown = (keyboardEvent: KeyboardEvent): boolean =>
  isOnlyShiftKey(keyboardEvent) || isShiftDirection(keyboardEvent);

export const hasShiftKeyUp = (keyboardEvent: KeyboardEvent): boolean =>
  isOnlyShiftKey(keyboardEvent) || !isShiftDirection(keyboardEvent);

export const compareSeverity = (a: ESeverity, b: ESeverity): number => {
  if (a === b) {
    return 0;
  } else if (a === ESeverity.Error) {
    return 1;
  } else if (a === ESeverity.Warning) {
    if (b === ESeverity.Error) {
      return -1;
    }
    return 1;
  }

  return -1;
};

export const sleep = async (milliseconds: number) => {
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const booleanOrNullToUid = (value: boolean | null): string =>
  value === null
    ? ''
    : value === true
    ? BOOLEAN_TRUE_LABEL
    : BOOLEAN_FALSE_LABEL;

export const simpleEqualityChecker = <T>(a: T, b: T): boolean => a === b;

/**
 * getFieldFromData(undefined, ...) // undefined
 * getFieldFromData(null, ...) // undefined
 * getFieldFromData({a: 1}, 'a') // 1
 * getFieldFromData({a: {b: 2}}, 'a') // {b: 2}
 * getFieldFromData({a: {b: 2}}, 'a.b') // 2
 */
export const getFieldFromData = (data: any, field: string): any => {
  return field
    .split('.')
    .reduce(
      (previousValue, currentValue) => previousValue?.[currentValue],
      data,
    );
};
