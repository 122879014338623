import { BulbFilled, BulbOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import ToggleSwitch from 'components/atoms/ToggleSwitch/ToggleSwitch';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { SaveButton } from 'components/organisms/ToEntitySummaryUiConfiguration/SaveButton';
import StyleCodingSelect from 'components/organisms/ToEntitySummaryUiConfiguration/StyleCodingSelect';
import {
  ConfigSection,
  ConfigSectionTitle,
  StyledLabel,
} from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import {
  COLUMN_DATA_MAP_AG_GRID,
  DEFAULT_LINKAGE_STYPE_MAP,
  DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE,
  DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE,
} from 'constants/Summary';
import { ALL_TIME_ZONE_OPTIONS } from 'constants/time';
import { EProfileSegment } from 'enums/ETag';
import { EActionState } from 'enums/General';
import { ETheme } from 'enums/Style';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';
import { IOption } from 'interfaces/Component';
import {
  IToEntityUiConfiguration,
  IToEntityUiConfigurationResponse,
  IUserUiConfiguration,
  IUserUiConfigurationResponse,
} from 'interfaces/Config';
import {
  IColumnConfiguration,
  ISummaryStyleCoding,
  ITableConfiguration,
} from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import {
  retrieveTenantUserConfigs,
  retrieveToEntityUiConfig,
  retrieveUserUiConfig,
  updateTenantUserConfigs,
  updateToEntityUiConfig,
} from 'services/configclient/config';
import styled from 'styled-components';
import { TTimeZone, TTimeZoneOption, TTimeZoneOptions } from 'types/DateTime';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';
import { isTimeZone } from 'utils/time';
import defaultTenantSummaryUiConfig from '../../../data/etagSummaryUiConfig.json';

const HorizontalLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Left = styled.div`
  width: 45%;
  height: 100%;
  border-right: 1px solid;
`;

const Right = styled.div`
  width: 55%;
  height: 100%;
  padding-left: 4px;
  padding-right: 4px;
`;

const StyledTimeZoneOptionsSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 57em;
`;
const StyledDefaultTimeZoneSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 10em;
`;
const StyledProfilesMaxHourRangeInputNumber = styled(InputNumber)`
  width: 20px;
`;
const StyledProfileSegmentSelect = styled(
  (props: ISelectProps<EProfileSegment>) => Select<EProfileSegment>(props),
)`
  width: 8em;
`;

// make error message use up same amount of vertical space
// when it is empty as when it is not empty
// this keeps screen from jumping when error message is
// made non-empty
const FixedHeightErrorMessage = styled(ErrorMessage)`
  min-height: 15px;
  display: inline-block;
`;

export interface IToEntitySummaryUiConfigurationProps
  extends IusePermissionsProps {
  children: ReactNode;
  saveable: boolean;
  saveableToEntities: IToEntity[];
  toEntity?: IToEntity;
}

const profileSegmentOptions = [
  {
    label: 'Generation',
    value: EProfileSegment.Gen,
  },
  {
    label: 'Load',
    value: EProfileSegment.Load,
  },
];

const ToEntitySummaryUiConfiguration = (
  props: IToEntitySummaryUiConfigurationProps,
): JSX.Element => {
  const themeSwitcher = useThemeSwitcher();
  const {
    children,
    encodedPermissionsId,
    encodedTenantPermissionsId,
    saveable,
    saveableToEntities,
    toEntity,
  } = props;
  const permissions = usePermissions(
    encodedPermissionsId,
    encodedTenantPermissionsId,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [summaryUiConfig, setSummaryUiConfig] = useState<
    IToEntityUiConfiguration | undefined
  >(undefined);
  const [summaryUiUserConfig, setSummaryUiUserConfig] = useState<
    IUserUiConfiguration | undefined
  >(undefined);
  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [defaultTimeZoneSelectionError, setDefaultTimeZoneSelectionError] =
    useState<string | undefined>(undefined);
  const [saveSummaryUiConfigurationError, setSaveSummaryUiConfigurationError] =
    useState<string | undefined>(undefined);
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [toEntitiesForSaving, setToEntitiesForSaving] = useState<IToEntity[]>(
    toEntity ? [toEntity] : [],
  );

  const showDealConfiguration: boolean = useMemo(() => {
    if (!summaryUiUserConfig) {
      return (
        Object.keys(COLUMN_DATA_MAP_AG_GRID).find((dataIndex: string) =>
          dataIndex.includes('deal'),
        ) !== undefined
      );
    }
    const allAvailableColumnConfig: IOption<ITableConfiguration> | undefined =
      summaryUiUserConfig.tableConfigurations.find(
        (config: IOption<ITableConfiguration>) =>
          config.label === 'All Available Columns',
      );
    if (allAvailableColumnConfig) {
      return (
        allAvailableColumnConfig.value.columns
          .map((column: IColumnConfiguration) => column.dataIndex)
          .find((dataIndex: string) => dataIndex.includes('deal')) !== undefined
      );
    }
    return (
      Object.keys(COLUMN_DATA_MAP_AG_GRID).find((dataIndex: string) =>
        dataIndex.includes('deal'),
      ) !== undefined
    );
  }, [summaryUiUserConfig]);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      if (toEntity) {
        const response: AxiosResponse<IToEntityUiConfigurationResponse> =
          await retrieveToEntityUiConfig(toEntity.to_entity);
        const toEntityUiConfigurationResponse: IToEntityUiConfigurationResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          setSummaryUiConfig(undefined);
          setLoadErrorMessage(
            'Could not load existing configuration. Please try again later.',
          );
        } else {
          setSummaryUiConfig(toEntityUiConfigurationResponse.response);
          setLoadErrorMessage(undefined);
        }
      } else {
        const response: AxiosResponse<any> = await retrieveTenantUserConfigs();
        const toEntityUiConfigurationResponse: IToEntityUiConfigurationResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          setSummaryUiConfig(undefined);
          setLoadErrorMessage(
            'Could not load existing configuration. Please try again later.',
          );
        } else {
          if (!toEntityUiConfigurationResponse.response.styleCoding) {
            toEntityUiConfigurationResponse.response.styleCoding =
              defaultTenantSummaryUiConfig.styleCoding;
          }
          if (!toEntityUiConfigurationResponse.response.profileSegment) {
            let defaultProfileSegment = EProfileSegment.Gen;
            switch (defaultTenantSummaryUiConfig.profileSegment.toLowerCase()) {
              case 'gen':
                defaultProfileSegment = EProfileSegment.Gen;
                break;
              case 'load':
                defaultProfileSegment = EProfileSegment.Load;
                break;
              case 'pod':
                defaultProfileSegment = EProfileSegment.Pod;
                break;
              default:
                defaultProfileSegment = EProfileSegment.Gen;
                break;
            }
            toEntityUiConfigurationResponse.response.profileSegment =
              defaultProfileSegment;
          }
          if (!toEntityUiConfigurationResponse.response.profilesMaxHourRange) {
            toEntityUiConfigurationResponse.response.profilesMaxHourRange =
              defaultTenantSummaryUiConfig.profilesMaxHourRange;
          }
          if (!toEntityUiConfigurationResponse.response.timeZoneOptions) {
            const timeZoneOptions: TTimeZoneOptions = [];
            defaultTenantSummaryUiConfig.timeZoneOptions.forEach((option) => {
              timeZoneOptions.push(option as TTimeZoneOption);
            });
            toEntityUiConfigurationResponse.response.timeZoneOptions =
              timeZoneOptions;
          }
          if (!toEntityUiConfigurationResponse.response.defaultTimeZone) {
            const defaultTimeZone = ALL_TIME_ZONE_OPTIONS.find(
              (option) =>
                option.value === defaultTenantSummaryUiConfig.defaultTimeZone,
            );
            if (defaultTimeZone) {
              toEntityUiConfigurationResponse.response.defaultTimeZone =
                defaultTimeZone.value;
            }
          }
          if (!toEntityUiConfigurationResponse.response.timeZones) {
            const timeZones: TTimeZone[] = [];
            defaultTenantSummaryUiConfig.timeZoneOptions.forEach((option) => {
              timeZones.push(option.value as TTimeZone);
            });
            toEntityUiConfigurationResponse.response.timeZones = timeZones;
          }
          setSummaryUiConfig(toEntityUiConfigurationResponse.response);
          setLoadErrorMessage(undefined);
        }
      }
    } catch (error: any) {
      if (toEntity) {
        captureError(
          error,
          `Error getting summary ui config for entity: ${toEntity.to_entity}`,
        );
      }

      setSummaryUiConfig(undefined);

      setLoadErrorMessage(
        'Could not load existing configuration. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
    //}, [toEntity.to_entity]);
  }, [toEntity]);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      if (toEntity) {
        const response: AxiosResponse<IUserUiConfigurationResponse> =
          await retrieveUserUiConfig(toEntity.to_entity);
        const userUiConfigurationResponse: IUserUiConfigurationResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          setSummaryUiUserConfig(undefined);
          setLoadErrorMessage(
            'Could not load existing configuration. Please try again later.',
          );
        } else {
          setSummaryUiUserConfig(userUiConfigurationResponse.response);
          setLoadErrorMessage(undefined);
        }
      }
    } catch (error: any) {
      if (toEntity) {
        captureError(
          error,
          `Error getting summary ui config for entity: ${toEntity.to_entity}`,
        );
      }

      setSummaryUiUserConfig(undefined);

      setLoadErrorMessage(
        'Could not load existing configuration. Please try again later.',
      );
    } finally {
      setLoading(false);
    }
  }, [toEntity]);

  const handleChangeTimeZoneOptions = (timeZoneIds: string[]) => {
    if (summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        timeZones: ALL_TIME_ZONE_OPTIONS.filter(
          (timeZoneOption: TTimeZoneOption) =>
            timeZoneIds.indexOf(timeZoneOption.value) >= 0,
        ).map(
          (timeZoneOption: TTimeZoneOption): TTimeZone => timeZoneOption.value,
        ),
      });
    }
  };

  const handleChangeDefaultTimeZone = (timeZoneId: string | undefined) => {
    if (isTimeZone(timeZoneId) && summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        defaultTimeZone: timeZoneId,
      });
    }
  };

  // es-lint recommended memoizing this function via useCallback
  const timeZoneOptionsSelectValues = useCallback((): TTimeZone[] => {
    return summaryUiConfig?.timeZones ?? [];
  }, [summaryUiConfig]);

  useEffect(() => {
    const tzselection = summaryUiConfig?.defaultTimeZone;
    let errorMessage: string | undefined = undefined;
    if (tzselection !== undefined) {
      const validValues: string[] = timeZoneOptionsSelectValues();
      if (validValues.indexOf(tzselection) < 0) {
        const tzselectionLabel = timeZoneOptionValueToLabel(tzselection);
        if (tzselectionLabel !== undefined) {
          errorMessage = `Time zone selection ${tzselectionLabel} is not valid.
            Please choose one of the valid options or add
            ${tzselectionLabel} to the list of valid options.`;
        }
      }
    }
    setDefaultTimeZoneSelectionError(errorMessage);
  }, [summaryUiConfig, timeZoneOptionsSelectValues]);

  const handleChangeProfilesMaxHourRange = (
    value: string | number | undefined,
  ) => {
    if (typeof value === 'number' && summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        profilesMaxHourRange: value,
      });
    }
  };

  const handleChangeProfileSegment = (val: EProfileSegment | undefined) => {
    if (val !== undefined && summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        profileSegment: val,
      });
    }
  };

  const handleSummaryProfilesBatchSizeChange = (
    value: string | number | undefined,
  ) => {
    if (typeof value === 'number' && summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        summaryProfilesBatchLoadSize: value,
      });
    }
  };

  const handleSummaryAttributesBatchSizeChange = (
    value: string | number | undefined,
  ) => {
    if (typeof value === 'number' && summaryUiConfig !== undefined) {
      setSummaryUiConfig({
        ...summaryUiConfig,
        summaryAttributesBatchLoadSize: value,
      });
    }
  };

  const timeZoneOptionValueToLabel = (
    timeZoneId: string | undefined,
  ): string | undefined => {
    return ALL_TIME_ZONE_OPTIONS.find(
      (tzoption: IOption<string>): boolean => tzoption.value === timeZoneId,
    )?.label;
  };

  const handleChangeStyleCoding = useCallback(
    (styleCoding: ISummaryStyleCoding) => {
      if (summaryUiConfig !== undefined) {
        setSummaryUiConfig({
          ...summaryUiConfig,
          styleCoding: styleCoding,
        });
      }
    },
    [summaryUiConfig],
  );

  const saveSummaryUiConfiguration = async () => {
    setActionState(EActionState.Actioning);
    if (summaryUiConfig === undefined) {
      setSaveSummaryUiConfigurationError(
        'Cannot save summary ui configuration. It has not been loaded yet.',
      );
      setActionState(EActionState.NoAction);
    } else {
      try {
        let responses: AxiosResponse<any>[] = [];
        responses = await Promise.all(
          toEntitiesForSaving.map((toEntityForSaving: IToEntity) =>
            updateToEntityUiConfig(
              toEntityForSaving.to_entity,
              summaryUiConfig,
            ),
          ),
        );
        if (!toEntity) {
          const response = await updateTenantUserConfigs(summaryUiConfig);
          responses.push(response);
        }
        const failed: string[] = [];
        for (let idx = 0; idx < responses.length; idx += 1) {
          if (!isSuccessStatus(responses[idx].status)) {
            failed.push(toEntitiesForSaving[idx].entity_code);
          }
        }
        if (failed.length === 0) {
          setSaveSummaryUiConfigurationError(undefined);
          setActionState(EActionState.Succeeded);
        } else {
          setSaveSummaryUiConfigurationError(
            `Failed to save configuration for ${failed.join(
              ', ',
            )}. Please try again later.`,
          );
          setActionState(EActionState.Failed);
        }
      } catch (error: any) {
        captureError(
          error,
          `Error saving summary ui configuration: ${JSON.stringify(
            summaryUiConfig,
          )}`,
        );

        setSaveSummaryUiConfigurationError(
          'Error occured. Please try again later.',
        );

        setActionState(EActionState.Failed);
      }
    }
  };

  useEffect(() => {
    // handle accounts that don't yet have style coding saved for ui_deal_status
    if (
      summaryUiConfig !== undefined &&
      summaryUiConfig.styleCoding !== undefined
    ) {
      if (summaryUiConfig.styleCoding['ui_deal_status'] === undefined) {
        handleChangeStyleCoding({
          ...summaryUiConfig.styleCoding,
          ui_deal_status: DEFAULT_LINKAGE_STYPE_MAP,
        });
      }
    }
  }, [handleChangeStyleCoding, summaryUiConfig]);

  return (
    <>
      <FixedHeightErrorMessage maxWidth='100%'>
        {loadErrorMessage}
      </FixedHeightErrorMessage>
      <HorizontalLayout>
        <Left>
          {children}
          <ConfigSection>
            <ConfigSectionTitle>Summary Ui Options</ConfigSectionTitle>
            {permissions.isDisplayable && (
              <>
                <StyledLabel>Time zone options:</StyledLabel>
                <StyledTimeZoneOptionsSelect
                  allowMultiple={true}
                  options={ALL_TIME_ZONE_OPTIONS}
                  values={timeZoneOptionsSelectValues()}
                  valueToUid={(s: string) => s}
                  onChangeMultiple={handleChangeTimeZoneOptions}
                  isLoading={loading}
                  isDisabled={!permissions.isExecutable}
                />
                <StyledLabel>Default time zone:</StyledLabel>
                <StyledDefaultTimeZoneSelect
                  options={ALL_TIME_ZONE_OPTIONS}
                  valueToUid={(s: string) => s}
                  onChange={handleChangeDefaultTimeZone}
                  value={timeZoneOptionValueToLabel(
                    summaryUiConfig?.defaultTimeZone,
                  )}
                  isLoading={loading}
                  isDisabled={!permissions.isExecutable}
                />
                {defaultTimeZoneSelectionError !== undefined && (
                  <ErrorMessage>{defaultTimeZoneSelectionError}</ErrorMessage>
                )}
                <StyledLabel>
                  Profiles max hour range (min: 24, max: 169):
                </StyledLabel>
                <StyledProfilesMaxHourRangeInputNumber
                  min={24}
                  max={169}
                  hideArrows={true}
                  onChange={handleChangeProfilesMaxHourRange}
                  value={summaryUiConfig?.profilesMaxHourRange}
                  isDisabled={!permissions.isExecutable}
                />
                <StyledLabel>Profile Segment:</StyledLabel>
                <StyledProfileSegmentSelect
                  options={profileSegmentOptions}
                  valueToUid={(s: EProfileSegment) => String(s)}
                  onChange={handleChangeProfileSegment}
                  value={summaryUiConfig?.profileSegment}
                  isLoading={loading}
                  isDisabled={!permissions.isExecutable}
                />
                <StyledLabel>Summary Attributes Batch Load Size:</StyledLabel>
                <InputNumber
                  hideArrows={true}
                  isDisabled={!permissions.isExecutable}
                  min={1}
                  onChange={handleSummaryAttributesBatchSizeChange}
                  value={
                    summaryUiConfig === undefined ||
                    summaryUiConfig.summaryAttributesBatchLoadSize === undefined
                      ? DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE
                      : summaryUiConfig.summaryAttributesBatchLoadSize
                  }
                />
                <StyledLabel>Summary Profiles Batch Load Size:</StyledLabel>
                <InputNumber
                  hideArrows={true}
                  isDisabled={!permissions.isExecutable}
                  min={1}
                  onChange={handleSummaryProfilesBatchSizeChange}
                  value={
                    summaryUiConfig === undefined ||
                    summaryUiConfig.summaryProfilesBatchLoadSize === undefined
                      ? DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE
                      : summaryUiConfig.summaryProfilesBatchLoadSize
                  }
                />
                {saveable ? (
                  <SaveButton
                    actionState={actionState}
                    errorMessage={saveSummaryUiConfigurationError}
                    onSave={saveSummaryUiConfiguration}
                    saveableToEntities={saveableToEntities}
                    setToEntitiesForSaving={setToEntitiesForSaving}
                    toEntity={toEntity}
                  />
                ) : null}
              </>
            )}
            {!permissions.isDisplayable &&
              (toEntity ? (
                <ErrorMessage>
                  {`You do not have permissions to view summary ui configuration for
                the Entity ${toEntity.entity_code}`}
                </ErrorMessage>
              ) : null)}
          </ConfigSection>
        </Left>
        <Right>
          <ConfigSection>
            <ConfigSectionTitle>Summary Ui Styling Options</ConfigSectionTitle>
            {permissions.isDisplayable && (
              <>
                <span>
                  Note: You are only editing styles for current theme, which is
                  "{themeSwitcher.currentTheme!.toUpperCase()}". Switch theme to
                  edit other styles. Use the button{' '}
                  <ToggleSwitch
                    checkedChildren={<BulbFilled />}
                    isDisabled={true}
                    isChecked={themeSwitcher.currentTheme === ETheme.Light}
                    unCheckedChildren={<BulbOutlined />}
                  />{' '}
                  in the upper right hand corner to switch.
                </span>
                <StyleCodingSelect
                  isDisabled={!permissions.isExecutable}
                  onChange={handleChangeStyleCoding}
                  showDealConfiguration={showDealConfiguration}
                  summaryStyleCoding={summaryUiConfig?.styleCoding}
                />
                {saveable ? (
                  <SaveButton
                    actionState={actionState}
                    errorMessage={saveSummaryUiConfigurationError}
                    onSave={saveSummaryUiConfiguration}
                    saveableToEntities={saveableToEntities}
                    setToEntitiesForSaving={setToEntitiesForSaving}
                    toEntity={toEntity}
                  />
                ) : null}
              </>
            )}
            {!permissions.isDisplayable &&
              (toEntity ? (
                <ErrorMessage>
                  {`You do not have permissions to view summary ui configuration for
                the Entity ${toEntity.entity_code}`}
                </ErrorMessage>
              ) : null)}
          </ConfigSection>
        </Right>
      </HorizontalLayout>
    </>
  );
};

export default ToEntitySummaryUiConfiguration;
