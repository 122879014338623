import { EPageMode } from 'enums/Page';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const usePageModeChange = (search: string) => {
  const navigate = useNavigate();

  const pageModeChange = useCallback(
    (pageMode: EPageMode) => {
      const query = new URLSearchParams(search);

      query.set('mode', pageMode);

      navigate({ pathname: '/detail', search: `?${query.toString()}` });
    },
    [navigate, search],
  );

  return pageModeChange;
};

export default usePageModeChange;
