import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IPageContentProps } from 'components/organisms/Page/Page';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import ETagCommonReasonNotesConfiguration from 'components/pages/ApprovalConfigsPage/ETagCommonReasonNotesConfiguration/ETagCommonReasonNotesConfiguration';
import ValidationReportsConfiguration from 'components/molecules/ValidationReportsConfiguration/ValidationReportsConfiguration';
import {
  PAGE_LAYOUT_STYLES,
  PUSH_RIGHT_VALUE,
  STANDARD_SPACING,
} from 'constants/styles';
import { IETagReasons } from 'interfaces/ETag';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { userSetSelectedToEntity } from 'reduxes/User/actions';
import { updateReasons } from 'services/approval/approval';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';
import { CONFIRM_MESSAGE_BEFORE_UNLOAD } from './constants';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';
import { TTimeZone } from '../../../types/DateTime';
import { getToEntityUserState } from '../../../utils/user';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { TThemeValue } from '../../../types/Style';
import { TRootState } from '../../../types/Redux';
import { ERetreiveState } from '../../../enums/General';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  padding: ${STANDARD_SPACING};

  > :not(:first-child) {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntitySelectionBar = styled(SeparatedRowLayout)`
  flex-shrink: 0;
  height: 40px;
`;

interface IApprovalConfigsPageContentProps
  extends IPageContentProps<undefined> {}

const ApprovalConfigsPageContent = ({
  userInfo,
}: IApprovalConfigsPageContentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedToEntity, toEntities, toEntityUserStates } = userInfo;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const timeZone: TTimeZone =
    getToEntityUserState(selectedToEntity, toEntityUserStates)
      ?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();

  const themeSwitcher = useThemeSwitcher();
  const currentTheme = themeSwitcher.currentTheme as TThemeValue;

  useEffect(() => {
    if (selectedToEntity === undefined && toEntities[0]) {
      const firstToEntity = toEntities[0];
      dispatch(userSetSelectedToEntity({ selectedToEntity: firstToEntity }));
      setIsDirty(false);
    }
  }, [dispatch, toEntities, selectedToEntity]);

  const saveToAll = async (reasons: IETagReasons) => {
    return Promise.all(
      toEntities.map(({ to_entity }) =>
        updateReasons(to_entity as TToEntityId, reasons),
      ),
    );
  };

  const handleBlockedNavigation = useCallback(
    (nextLocation: { pathname: string; search: string }) => {
      if (isDirty) {
        if (window.confirm(CONFIRM_MESSAGE_BEFORE_UNLOAD)) {
          // Resets isDirty
          setIsDirty(false);

          if (toEntities[0] !== selectedToEntity) {
            dispatch(
              userSetSelectedToEntity({ selectedToEntity: selectedToEntity }),
            );
          }

          // Proceed with navigation
          navigate(nextLocation.pathname + nextLocation.search);
        }
      }
    },
    [isDirty, setIsDirty, selectedToEntity, dispatch, toEntities, navigate],
  );

  // Handle in-app navigation
  useEffect(() => {
    if (
      location !== prevLocationRef.current &&
      location.pathname !== prevLocationRef.current.pathname
    ) {
      handleBlockedNavigation(location);
      prevLocationRef.current = location;
    }
  }, [location, handleBlockedNavigation]);

  // Handle browser navigation events
  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = true;
        return '';
      }
    };

    if (isDirty) {
      window.addEventListener('beforeunload', unloadHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [isDirty, selectedToEntity]);

  const onConfirm = useCallback(
    () => (isDirty ? window.confirm(CONFIRM_MESSAGE_BEFORE_UNLOAD) : true),
    [isDirty],
  );

  const encodedPermissionsId = selectedToEntity
    ? encodeIds(['eTagApprovalConfigs'], selectedToEntity.to_entity)
    : undefined;

  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);

  const configState = useSelector((state: TRootState) => state.config);
  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        selectedToEntity &&
        configState.hideTenantTitleConfig.get(selectedToEntity.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, selectedToEntity, setHideTenantTitle]);

  return (
    <Layout>
      <SeparatedRowLayout>
        {selectedToEntity && selectedToEntity.to_entity ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            timeZone={timeZone}
            toEntity={selectedToEntity}
          />
        ) : null}
        {hideTenantTitle ? null : (
          <ToEntitySelectionBar>
            <UserToEntitySelection onConfirmBeforeChange={onConfirm} />
          </ToEntitySelectionBar>
        )}
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            toEntityId={selectedToEntity?.to_entity}
          />
        </NavigationActions>
      </SeparatedRowLayout>
      {selectedToEntity === undefined ? null : (
        <>
          <ETagCommonReasonNotesConfiguration
            {...{
              selectedToEntity,
              saveToAll,
              setIsDirty,
              isDirty,
              entityCount: toEntities.length,
              encodedPermissionsId: encodeIds(
                ['eTagApprovalConfigs'],
                `${selectedToEntity.to_entity}`,
              ),
              onConfirmBeforeReload: onConfirm,
            }}
          />
          <ValidationReportsConfiguration
            encodedPermissionsId={encodeIds(
              ['eTagFailedValidationsReportConfiguration'],
              selectedToEntity.to_entity,
            )}
            toEntity={selectedToEntity}
          />
        </>
      )}
    </Layout>
  );
};

export default ApprovalConfigsPageContent;
