import {
  EDITOR_OPTIONS,
  HOUR_EXTRA_SUFFIX,
  HOUR_KEY_REGEX,
  MARKET_INFO_PRICES_DATA_GRID_CELL_HOUR_KEY,
  MARKET_INFO_PRICES_DATA_GRID_CELL_MW_KEY,
  MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY,
} from 'components/organisms/MarketInformation/MarketInfoPrices/constants';
import MarketInfoPricesDataGridCellEdit from 'components/organisms/MarketInformation/MarketInfoPricesDataGridCellEdit/MarketInfoPricesDataGridCellEdit';
import MarketInfoPricesDataGridCellReview from 'components/organisms/MarketInformation/MarketInfoPricesDataGridCellReview/MarketInfoPricesDataGridCellReview';
import { ID_KEY } from 'constants/General';
import {
  DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
  MARKET_INFO_PRICES_DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
} from 'constants/styles';
import { EMarketInfoPricesDataGridCellType } from 'enums/Detail';
import { EMarketInfoMarket } from 'enums/ETag';
import { EDateTimeRangeDST } from 'enums/Time';
import { IDataGridSelectionContext } from 'interfaces/Component';
import { IMarketInfoPricesDataGridCell } from 'interfaces/Detail';
import {
  IETagMarketInfo,
  IETagMarketInfoPrice,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import { Context } from 'react';
import { EditorProps, FormatterProps } from 'react-data-grid';
import {
  TMarketInfoPricesDataGridColumn,
  TMarketInfoPricesDataGridRow,
} from 'types/Detail';
import { isEmptyValue } from 'utils/general';
import { getDateTimeRangeDstType } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getMarketInfoPricesDataGridColumns = (
  initialMarketInfoPricesData?: TMarketInfoPricesDataGridRow[],
): TMarketInfoPricesDataGridColumn[] => {
  const getEditor =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: EditorProps<TMarketInfoPricesDataGridRow, IIndexable>,
    ): JSX.Element =>
      (
        <MarketInfoPricesDataGridCellEdit
          {...props}
          DataGridSelectionContext={DataGridSelectionContext}
        />
      );
  const getFormatter =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: FormatterProps<TMarketInfoPricesDataGridRow, IIndexable>,
    ): JSX.Element =>
      (
        <MarketInfoPricesDataGridCellReview
          {...props}
          DataGridSelectionContext={DataGridSelectionContext}
          initialDataSet={initialMarketInfoPricesData}
        />
      );

  return [
    {
      getFormatter,
      name: 'Hour',
      key: MARKET_INFO_PRICES_DATA_GRID_CELL_HOUR_KEY,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    },
    {
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      name: 'Price\n($/MW)',
      key: MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY,
      width: MARKET_INFO_PRICES_DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    },
    {
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      name: 'MW',
      key: MARKET_INFO_PRICES_DATA_GRID_CELL_MW_KEY,
      width: MARKET_INFO_PRICES_DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    },
  ];
};

const getHourKeyForHour = (hour: number, isExtraHour?: boolean): string =>
  `HE-${hour.toString().padStart(2, '0')}${isExtraHour === true ? 'd' : ''}`;

export const generatePricesForDate = (
  marketDate: ZonedDateTime,
  marketInfoPrices: IETagMarketInfoPrice[],
): IETagMarketInfoPrice[] => {
  const prices: IETagMarketInfoPrice[] = [];
  const dateTimeRangeDST: EDateTimeRangeDST = getDateTimeRangeDstType(
    marketDate.startOf('day'),
    marketDate.startOf('day').add(1, 'day'),
  );

  for (let hour: number = 1; hour <= 24; hour += 1) {
    let hourKey: string = getHourKeyForHour(hour);
    let marketInfoPrice: IETagMarketInfoPrice | undefined =
      marketInfoPrices.find(
        (marketInfoPrice: IETagMarketInfoPrice): boolean =>
          marketInfoPrice.hour === hourKey,
      );

    if (hour === 2) {
      if (dateTimeRangeDST !== EDateTimeRangeDST.Shorter) {
        prices.push({
          hour: hourKey,
          mw: marketInfoPrice === undefined ? null : marketInfoPrice.mw,
          price: marketInfoPrice === undefined ? null : marketInfoPrice.price,
        });
      }

      if (dateTimeRangeDST === EDateTimeRangeDST.Longer) {
        hourKey = getHourKeyForHour(2, true);

        marketInfoPrice = marketInfoPrices.find(
          (marketInfoPrice: IETagMarketInfoPrice): boolean =>
            marketInfoPrice.hour === hourKey,
        );

        prices.push({
          hour: hourKey,
          mw: marketInfoPrice === undefined ? null : marketInfoPrice.mw,
          price: marketInfoPrice === undefined ? null : marketInfoPrice.price,
        });
      }
    } else {
      prices.push({
        hour: hourKey,
        mw: marketInfoPrice === undefined ? null : marketInfoPrice.mw,
        price: marketInfoPrice === undefined ? null : marketInfoPrice.price,
      });
    }
  }

  return prices;
};

export const getMarketInfoPrices = (
  market: EMarketInfoMarket,
  marketInfos?: IETagMarketInfo[],
): IETagMarketInfoPrice[] => {
  const marketInfo: IETagMarketInfo | undefined = marketInfos?.find(
    (marketInfo: IETagMarketInfo): boolean =>
      marketInfo.market_info_market === market,
  );

  if (marketInfo !== undefined) {
    switch (market) {
      case EMarketInfoMarket.MISO: {
        return (marketInfo.data as IETagMisoMarketData).miso_price_list ?? [];
      }
      case EMarketInfoMarket.SPP: {
        return (marketInfo.data as IETagSppMarketData).spp_price_list ?? [];
      }
      default: {
        // typescript knows we should not get here, but we know people can use escape hatches to pass invalid input.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Invalid market: ${market}`);
      }
    }
  }

  return [];
};

const getDisplayHourForHourKey = (hourKey: string): string => {
  const matches: RegExpMatchArray | null = hourKey.match(HOUR_KEY_REGEX);

  if (matches === null) {
    throw new Error(`Invalid hourKey: ${hourKey}`);
  }

  return `HE ${matches[1]}${matches[2] === HOUR_EXTRA_SUFFIX ? '*' : ''}`;
};

export const getMarketInfoPricesDataGridRows = (
  marketInfoPrices: IETagMarketInfoPrice[],
): TMarketInfoPricesDataGridRow[] =>
  marketInfoPrices.map(
    (marketInfoPrice: IETagMarketInfoPrice): TMarketInfoPricesDataGridRow => {
      const { hour, mw, price } = marketInfoPrice;

      return {
        [ID_KEY]: {
          type: EMarketInfoPricesDataGridCellType.String,
          value: hour,
        },
        [MARKET_INFO_PRICES_DATA_GRID_CELL_HOUR_KEY]: {
          type: EMarketInfoPricesDataGridCellType.String,
          value: getDisplayHourForHourKey(hour),
        },
        [MARKET_INFO_PRICES_DATA_GRID_CELL_MW_KEY]:
          mw === null
            ? null
            : {
                type: EMarketInfoPricesDataGridCellType.Number,
                value: mw,
              },
        [MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY]:
          price === null
            ? null
            : {
                type: EMarketInfoPricesDataGridCellType.Number,
                value: price,
              },
      };
    },
  );

export const marketInfoPricesDataGridRowsToMarketInfoPrices = (
  marketInfoPricesDataGridRows: TMarketInfoPricesDataGridRow[],
): IETagMarketInfoPrice[] =>
  marketInfoPricesDataGridRows.map(
    (
      marketInfoPricesDataGridRow: TMarketInfoPricesDataGridRow,
    ): IETagMarketInfoPrice => {
      const keyCell: IMarketInfoPricesDataGridCell | null | undefined =
        marketInfoPricesDataGridRow[ID_KEY];

      if (keyCell === null || keyCell === undefined) {
        throw new Error(`Missing keyCell`);
      } else if (keyCell.type !== EMarketInfoPricesDataGridCellType.String) {
        throw new Error(`Invalid keyCell: ${JSON.stringify(keyCell)}`);
      }

      const hourCell: IMarketInfoPricesDataGridCell | null | undefined =
        marketInfoPricesDataGridRow[MARKET_INFO_PRICES_DATA_GRID_CELL_HOUR_KEY];

      if (hourCell === null || hourCell === undefined) {
        throw new Error(`Missing hourCell`);
      } else if (hourCell.type !== EMarketInfoPricesDataGridCellType.String) {
        throw new Error(`Invalid hourCell: ${JSON.stringify(hourCell)}`);
      }

      const mwCell: IMarketInfoPricesDataGridCell | null | undefined =
        marketInfoPricesDataGridRow[MARKET_INFO_PRICES_DATA_GRID_CELL_MW_KEY];

      if (mwCell === undefined) {
        throw new Error(`Missing mwCell`);
      } else if (
        !(
          mwCell === null ||
          mwCell.type === EMarketInfoPricesDataGridCellType.Empty ||
          mwCell.type === EMarketInfoPricesDataGridCellType.Number
        )
      ) {
        throw new Error(`Invalid mwCell: ${JSON.stringify(mwCell)}`);
      }

      const priceCell: IMarketInfoPricesDataGridCell | null | undefined =
        marketInfoPricesDataGridRow[
          MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY
        ];

      if (priceCell === undefined) {
        throw new Error(`Missing priceCell`);
      } else if (
        !(
          priceCell === null ||
          priceCell.type === EMarketInfoPricesDataGridCellType.Empty ||
          priceCell.type === EMarketInfoPricesDataGridCellType.Number
        )
      ) {
        throw new Error(`Invalid priceCell: ${JSON.stringify(priceCell)}`);
      }

      return {
        hour: keyCell.value as string,
        mw:
          mwCell === null ||
          mwCell.type === EMarketInfoPricesDataGridCellType.Empty ||
          isEmptyValue(mwCell.value)
            ? null
            : parseFloat(mwCell.value as string),
        price:
          priceCell === null ||
          priceCell.type === EMarketInfoPricesDataGridCellType.Empty ||
          isEmptyValue(priceCell.value)
            ? null
            : parseFloat(priceCell.value as string),
      };
    },
  );
