import { TToEntityId } from 'types/ToEntity';

export const CONFIG_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/config`;

export const CONFIG_SUMMARY_UI_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/config/summaryUi`;

export const CONFIG_SUMMARY_UI_USER_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/config/summaryUiUser`;

export const CONFIG_DAILY_PROFILE_TEMPLATES_ROUTE = (
  toEntityId: TToEntityId,
  timeZoneId: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/dailyProfileTemplates?timeZoneId=${encodeURIComponent(timeZoneId)}`;

export const CONFIG_DAILY_PROFILE_SINGLE_TEMPLATE_ROUTE = (
  toEntityId: TToEntityId,
  templateId: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/dailyProfileTemplates/${encodeURIComponent(templateId)}`;

export const CONFIG_CUSTOM_HOLIDAYS_ROUTE = (
  toEntityId: TToEntityId,
  startDay: string,
  stopDay: string,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/holidayList?startDay=${encodeURIComponent(
    startDay,
  )}&stopDay=${encodeURIComponent(stopDay)}`;

export const TENANT_TOENTITIES_ROUTE = (): string => `/tenantToEntities`;
export const TENANT_USER_CONFIGS__SUMMARY_UI_ROUTE = (): string =>
  `/tenantUserConfigs/summaryUi`;
