import eTagAttributeColumnsAgGrid from 'data/eTagAttributeColumnsAgGrid.json';
import eTagDealLinkageColumnsAgGrid from 'data/eTagDealLinkageColumnsAgGrid.json';
import eTagProfileColumnsAgGrid from 'data/eTagProfileColumnsAgGrid.json';
import { ELinkageStatus } from 'enums/ETag';
import { ETheme } from 'enums/Style';
import { EDefaultDateRange } from 'enums/Summary';
import { ISummaryThemedStyles } from 'interfaces/Summary';
import { TETagColumnDataMapAgGrid } from 'types/ETag';

export const COLUMN_DATA_MAP_AG_GRID: TETagColumnDataMapAgGrid = {
  ...eTagAttributeColumnsAgGrid,
  ...eTagDealLinkageColumnsAgGrid,
  ...eTagProfileColumnsAgGrid,
} as TETagColumnDataMapAgGrid;

export const DATE_RANGE_LABEL_MAP: Record<EDefaultDateRange, string> = {
  [EDefaultDateRange.Today]: 'Today',
  [EDefaultDateRange.Tomorrow]: 'Tomorrow',
  [EDefaultDateRange.Yesterday]: 'Yesterday',
  [EDefaultDateRange.TodayTomorrow]: 'Today + tomorrow',
  [EDefaultDateRange.YesterdayToday]: 'Yesterday + today',
  [EDefaultDateRange.TodayPlus2Days]: 'Today + 2 days',
  [EDefaultDateRange.TodayPlus3Days]: 'Today + 3 days',
  [EDefaultDateRange.TodayPlus4Days]: 'Today + 4 days',
  [EDefaultDateRange.LastWeek]: 'Last week',
  [EDefaultDateRange.ThisWeek]: 'This week',
  [EDefaultDateRange.NextWeek]: 'Next week',
  [EDefaultDateRange.LastMonth]: 'Last month',
  [EDefaultDateRange.ThisMonth]: 'This month',
  [EDefaultDateRange.NextMonth]: 'Next month',
};

export const DEFAULT_LINKAGE_STYPE_MAP: Record<
  ELinkageStatus,
  ISummaryThemedStyles
> = {
  [ELinkageStatus.Unlinked]: {
    [ETheme.Dark]: {},
    [ETheme.Light]: {},
  },
  [ELinkageStatus.PartiallyLinked]: {
    [ETheme.Dark]: {
      'background-color': 'Khaki',
    },
    [ETheme.Light]: {
      'background-color': 'Khaki',
    },
  },
  [ELinkageStatus.FullyLinked]: {
    [ETheme.Dark]: {
      'background-color': '#ABD981',
    },
    [ETheme.Light]: {
      'background-color': '#ABD981',
    },
  },
  [ELinkageStatus.OverLinked]: {
    [ETheme.Dark]: {
      'background-color': '#fa594d',
    },
    [ETheme.Light]: {
      'background-color': '#fa594d',
    },
  },
};

export const ATTRIBUTE_ROW_STYLE_KEY = 'summary_attribute_row_styles';
export const PROFILES_ROW_STYLE_KEY = 'summary_profiles_row_styles';
export const DRAFT_ROW_STYLE_KEY = 'draft_row_styles';
export const HOUR_ENDING_STYLE_KEY = 'summary_profile_hour_ending_styles';
export const ROW_HIGHLIGHTING_STYLE_KEY = 'rowHighlightingStyle';
export const ALTERNATING_ROW_STYLE_KEY = 'alternatingRowStyle';

export const DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE = 40;
export const DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE = 10;

export const SUMMARY_PROFILE_TOTAL_LABEL = 'Profile Totals (MWh)';
