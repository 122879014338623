import { IOption } from 'interfaces/Component';
import {
  IETagCheckoutReport,
  IETagCheckoutReportDataSet,
} from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { IColumnConfiguration, ITableConfiguration } from 'interfaces/Summary';
import { TTimeZone } from 'types/DateTime';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

/**
 * The backend uses different attribute names than
 * the attribute names of the columns on the UI
 * so we must map the UI column names to the backend attribute.
 * See lambdas.report.summaryCheckoutReportUtil.SummaryCheckoutReportColumns to see what is supported.
 * There is a unit test checking this against COLUMN_DATA_MAP_AG_GRID
 */
export function dataIndexToAttributeNames(dataIndex: string): string[] {
  switch (dataIndex) {
    case 'tag_code':
    case 'composite_state':
    case 'last_request_time':
    case 'last_request_status':
    case 'last_request_id':
    case 'curtailed':
    case 'pending_requests':
    case 'approval_right':
    case 'contact':
    case 'terminated_cancelled':
    case 'denied':
    case 'withdrawn':
    case 'rps_ids':
    case 'arefs':
    case 'caiso_contracts':
    case 'caiso_products':
    case 'caiso_res_ids':
    case 'caiso_priority_types':
    case 'total_mw': {
      return [dataIndex.toLocaleUpperCase()];
    }
    case 'ui_tag_id':
    case 'ui_gca':
    case 'ui_gpe':
    case 'ui_lse':
    case 'ui_lca':
    case 'ui_cpse':
    case 'ui_source':
    case 'ui_sink': {
      return [dataIndex.replace(/^ui_/, '').toLocaleUpperCase()];
    }
    case 'transaction_type': {
      return ['TAG_TYPE'];
    }
    case 'notes': {
      return ['TAG_NOTES'];
    }
    case 'test_flag': {
      return ['TEST'];
    }
    case 'creation_time': {
      return ['TAG_CREATED_TIME'];
    }
    case 'start_date': {
      return ['TAG_START_TIME'];
    }
    case 'end_date': {
      return ['TAG_STOP_TIME'];
    }
    case 'act_on_by_time': {
      return ['APPROVAL_DEADLINE'];
    }
    case 'approved_termination_time': {
      return ['TERMINATION_TIME'];
    }
    case 'denied_withdrawn': {
      return ['DENIED', 'WITHDRAWN'];
    }
    case 'is_subhourly_tag': {
      return ['SUBHOURLY'];
    }
    case 'day': {
      return ['DATE'];
    }
    case 'on_peak_total_mw': {
      return ['ON_PEAK_TOTAL'];
    }
    case 'off_peak_total_mw': {
      return ['OFF_PEAK_TOTAL'];
    }
    case 'hour_endings': {
      return [
        'HE01',
        'HE02',
        'HE03',
        'HE04',
        'HE05',
        'HE06',
        'HE07',
        'HE08',
        'HE09',
        'HE10',
        'HE11',
        'HE12',
        'HE13',
        'HE14',
        'HE15',
        'HE16',
        'HE17',
        'HE18',
        'HE19',
        'HE20',
        'HE21',
        'HE22',
        'HE23',
        'HE24',
      ];
    }
    default: {
      return [];
    }
  }
}

/**
 * The backend uses different attribute names than
 * the attribute names of the columns on the UI
 * so we must map the UI column names to the backend attribute
 */
export const getAttributeNamesFromTableConfiguration = (
  config: ITableConfiguration,
): string[] => {
  const attributeNames: string[] = [];

  config.columns.forEach((column: IColumnConfiguration) => {
    const attributeNamesForColumn = dataIndexToAttributeNames(column.dataIndex);
    attributeNames.push(...attributeNamesForColumn);
    console.log('after', column, attributeNames);
  });

  return attributeNames;
};

const getFilterNameFromId = (
  filterId: string,
  filterOptions: IOption<ICustomFilter>[],
): string => {
  const filterOption: IOption<ICustomFilter> | undefined = filterOptions.find(
    (option: IOption<ICustomFilter>) => option.value.filter_id === filterId,
  );
  return !isEmptyValue(filterOption?.value.filter_name)
    ? filterOption?.value.filter_name?.trim() ?? ''
    : 'AllTags';
};

/**
 * @returns A dataset with the string used to display
 * the filename in the checkout report review table
 */
export const getCheckoutDataSetFromData = (
  checkoutData: IETagCheckoutReport[],
  filterOptions: IOption<ICustomFilter>[],
  timeZone: TTimeZone,
): IETagCheckoutReportDataSet[] => {
  return checkoutData.map(
    (checkoutDatum: IETagCheckoutReport): IETagCheckoutReportDataSet => ({
      ...checkoutDatum,
      file: {
        fileName: `${ZonedDateTime.parseIso(
          checkoutDatum.request_time,
          timeZone,
        ).fileFormat()}_${getFilterNameFromId(
          checkoutDatum.filter_ids,
          filterOptions,
        ).replaceAll(' ', '_')}_${ZonedDateTime.parseIso(
          checkoutDatum.start,
          timeZone,
        ).fileFormat()}_${ZonedDateTime.parseIso(
          checkoutDatum.end,
          timeZone,
        ).fileFormat()}.csv`,
        fileUrl: checkoutDatum.report_url,
        inProgress: checkoutDatum.in_progress,
      },
    }),
  );
};

/**
 * @returns sorts IETagCheckoutReport objects by request time, most recent first
 */
export const checkoutReportSorter = (
  a: IETagCheckoutReport,
  b: IETagCheckoutReport,
): number =>
  // can't pass a timezone to this sorter, but all items should have
  //the same timezone, so we use UTC to compare them to each other
  ZonedDateTime.parseIso(a.request_time, 'UTC').isBefore(
    ZonedDateTime.parseIso(b.request_time, 'UTC'),
  )
    ? 1
    : ZonedDateTime.parseIso(a.request_time, 'UTC').isAfter(
        ZonedDateTime.parseIso(b.request_time, 'UTC'),
      )
    ? -1
    : 0;
