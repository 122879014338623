import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  IGetTenantToEntitiesResponse,
  IToEntityUiConfiguration,
  IToEntityUiConfigurationResponse,
  IUpdateUserUiConfigurationResponse,
  IUserUiConfiguration,
  IUserUiConfigurationResponse,
  IProductProfileIdsResponse,
  IDailyProductProfileResponse,
  ICustomHolidayListResponse,
} from 'interfaces/Config';
import { IETagConfigResponse } from 'interfaces/ETag';
import {
  CONFIG_ROUTE,
  CONFIG_SUMMARY_UI_ROUTE,
  CONFIG_SUMMARY_UI_USER_ROUTE,
  CONFIG_DAILY_PROFILE_TEMPLATES_ROUTE,
  CONFIG_DAILY_PROFILE_SINGLE_TEMPLATE_ROUTE,
  CONFIG_CUSTOM_HOLIDAYS_ROUTE,
  TENANT_TOENTITIES_ROUTE,
  TENANT_USER_CONFIGS__SUMMARY_UI_ROUTE,
} from 'services/configclient/constants';
import { updateToEntityUiConfigurationResponse } from 'services/configclient/helpers';
import {
  CONFIG_CLIENT_SERVICE_API_NAME,
  DEFAULT_OPTIONS,
} from 'services/constants';
import { TToEntityId } from 'types/ToEntity';
import { TTimeZone } from '../../types/DateTime';

export const retrieveToEntityConfig = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IETagConfigResponse>> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

/**
 * Get the to-entity-wide configurations for the ui.
 * @param toEntityId required
 * @return IToEntityUiConfig if status code is 200
 */
export const retrieveToEntityUiConfig = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IToEntityUiConfigurationResponse>> => {
  const response: AxiosResponse<IToEntityUiConfigurationResponse> =
    await API.get(
      CONFIG_CLIENT_SERVICE_API_NAME,
      CONFIG_SUMMARY_UI_ROUTE(toEntityId),
      DEFAULT_OPTIONS,
    );

  return updateToEntityUiConfigurationResponse(response);
};

export const updateToEntityUiConfig = async (
  toEntityId: TToEntityId,
  toEntityUiConfig: IToEntityUiConfiguration,
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: toEntityUiConfig,
  };
  return await API.put(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_SUMMARY_UI_ROUTE(toEntityId),
    options,
  );
};

export const retrieveUserUiConfig = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IUserUiConfigurationResponse>> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_SUMMARY_UI_USER_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const updateUserUiConfig = async (
  toEntityId: TToEntityId,
  userUiConfig: IUserUiConfiguration,
): Promise<AxiosResponse<IUpdateUserUiConfigurationResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: userUiConfig,
  };
  return await API.put(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_SUMMARY_UI_USER_ROUTE(toEntityId),
    options,
  );
};

export const retrieveDailyProfileTemplates = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IProductProfileIdsResponse>> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_DAILY_PROFILE_TEMPLATES_ROUTE(toEntityId, timeZone),
    DEFAULT_OPTIONS,
  );
};

export const retrieveSingleDailyProfileTemplate = async (
  toEntityId: TToEntityId,
  templateId: string,
): Promise<AxiosResponse<IDailyProductProfileResponse>> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_DAILY_PROFILE_SINGLE_TEMPLATE_ROUTE(toEntityId, templateId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveCustomHolidayList = async (
  toEntityId: TToEntityId,
  startDay: string,
  stopDay: string,
): Promise<AxiosResponse<ICustomHolidayListResponse>> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    CONFIG_CUSTOM_HOLIDAYS_ROUTE(toEntityId, startDay, stopDay),
    DEFAULT_OPTIONS,
  );
};

export const retrieveToEntitiesForPCISupport = async (): Promise<
  AxiosResponse<IGetTenantToEntitiesResponse>
> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    TENANT_TOENTITIES_ROUTE(),
    DEFAULT_OPTIONS,
  );
};

export const retrieveTenantUserConfigs = async (): Promise<
  AxiosResponse<any>
> => {
  return await API.get(
    CONFIG_CLIENT_SERVICE_API_NAME,
    TENANT_USER_CONFIGS__SUMMARY_UI_ROUTE(),
    DEFAULT_OPTIONS,
  );
};

export const updateTenantUserConfigs = async (
  userUiConfig: any,
): Promise<AxiosResponse<any>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: userUiConfig,
  };
  return await API.put(
    CONFIG_CLIENT_SERVICE_API_NAME,
    TENANT_USER_CONFIGS__SUMMARY_UI_ROUTE(),
    options,
  );
};
